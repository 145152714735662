<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card>
        <vs-prompt
          class="calendar-event-dialog"
          title="Commande verre"
          accept-text= "Valider"
          @accept="PassCommandeVerre"
          :is-valid="validForm"
          :active.sync="activePromptCommandeVerre">

            <table class="w-full" cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th class="customth" style="border: none"></th>
                  <th class="customth" style="border-width: 0px;">Sph</th>
                  <th class="customth" style="border-width: 0px;">Cyl</th>
                  <th class="customth" style="border-width: 0px;">Axe</th>
                  <th class="customth" style="border-width: 0px;">Add</th>
                  <th class="customth" style="border-width: 0px;">Diam</th>
                </tr>
                <tr>
                  <th class="customth" style="border-width: 0px;">OD</th>
                  <th class="customth" style="border-width: 0px;">{{SphVD}}</th>
                  <th class="customth" style="border-width: 0px;">{{CylVD}}</th>
                  <th class="customth" style="border-width: 0px;">{{AxeVD}}°</th>
                  <th class="customth" style="border-width: 0px;">{{AddVD}}</th>
                  <th class="customth" style="border-width: 0px;"></th>
                </tr>
                <tr>
                  <th class="customth" style="border-width: 0px;">OG</th>
                  <th class="customth" style="border-width: 0px;">{{SphVG}}</th>
                  <th class="customth" style="border-width: 0px;">{{CylVG}}</th>
                  <th class="customth" style="border-width: 0px;">{{AxeVG ? `${AxeVG}°` : ''}}</th>
                  <th class="customth" style="border-width: 0px;">{{AddVG}}</th>
                  <th class="customth" style="border-width: 0px;"></th>
                </tr>
              </thead>
            </table>
            <vs-tabs>
              <vs-tab label="Commande 1">
                <div class="my-4">
                  <small class="date-label">Date</small>
                  <datepicker :language="$vs.rtl ? langHe : langFr" name="dateCommandeVerre" v-model="dateCommandeVerre1"></datepicker>
                </div>
                <div class="vx-col w-ful">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="fournVerre1">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col w-ful mt-5">
                  <vs-select label="Type de verre" class="w-full mt-4"  autocomplete v-model="typeVerre">
                    <vs-select-item value="Simple foyer" text="Simple foyer" />
                    <vs-select-item value="Double foyer" text="Double foyer" />
                    <vs-select-item value="Progessif" text="Progessif" />
                  </vs-select>
                </div>
                <div class="vx-col w-full">
                  <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre Droit" v-model="RefVerD1"></vs-input>
                </div>
                <div class="vx-col w-full">
                  <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre gauche" v-model="RefVerG1"></vs-input>
                </div>
              </vs-tab>
              <vs-tab label="Commande 2">
                <div class="my-4">
                  <small class="date-label">Date</small>
                  <datepicker :language="$vs.rtl ? langHe : langFr" name="dateCommandeVerre" v-model="dateCommandeVerre2"></datepicker>
                </div>
                <div class="vx-col w-ful">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="fournVerre2">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col w-ful mt-5">
                  <vs-select label="Type de verre" class="w-full mt-4"  autocomplete v-model="typeVerre2">
                    <vs-select-item value="Simple foyer" text="Simple foyer" />
                    <vs-select-item value="Double foyer" text="Double foyer" />
                    <vs-select-item value="Progessif" text="Progessif" />
                  </vs-select>
                </div>
                <div class="vx-col w-full">
                  <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre Droit" v-model="RefVerD2"></vs-input>
                </div>
                <div class="vx-col w-full">
                  <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre gauche" v-model="RefVerG2"></vs-input>
                </div>
              </vs-tab>
              <vs-tab label="Commande 3">
                <div class="my-4">
                  <small class="date-label">Date</small>
                  <datepicker :language="$vs.rtl ? langHe : langFr" name="dateCommandeVerre" v-model="dateCommandeVerre3"></datepicker>
                </div>
                <div class="vx-col w-ful">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="fournVerre3">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col w-ful mt-5">
                  <vs-select label="Type de verre" class="w-full mt-4"  autocomplete v-model="typeVerre3">
                    <vs-select-item value="Simple foyer" text="Simple foyer" />
                    <vs-select-item value="Double foyer" text="Double foyer" />
                    <vs-select-item value="Progessif" text="Progessif" />
                  </vs-select>
                </div>
                <div class="vx-col w-full">
                  <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre Droit" v-model="RefVerD3"></vs-input>
                </div>
                <div class="vx-col w-full">
                  <vs-input name="event-name" v-validate="'required'" class="w-full mt-5" label="Verre gauche" v-model="RefVerG3"></vs-input>
                </div>

              </vs-tab>
            </vs-tabs>

        </vs-prompt>
        <vs-prompt
          class="calendar-event-dialog"
          title="Verre commandée"
          accept-text= "Validé la livraison"
          cancelText="Retourné la commande"
          @accept="valideLivraisonCommandeVerre"
          @cancel="RetourCommandeVerre"
          :is-valid="validForm"
          :active.sync="activePromptValideCommandeVerre">

            <table class="w-full"  cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th class="customth" style="border: none"></th>
                  <th class="customth" style="border-width: 0px;">Sph</th>
                  <th class="customth" style="border-width: 0px;">Cyl</th>
                  <th class="customth" style="border-width: 0px;">Axe</th>
                  <th class="customth" style="border-width: 0px;">Add</th>
                  <th class="customth" style="border-width: 0px;">Diam</th>
                </tr>
                <tr>
                  <th class="customth" style="border-width: 0px;">OD</th>
                  <th class="customth" style="border-width: 0px;">{{SphVD}}</th>
                  <th class="customth" style="border-width: 0px;">{{CylVD}}</th>
                  <th class="customth" style="border-width: 0px;">{{AxeVD}}°</th>
                  <th class="customth" style="border-width: 0px;">{{AddVD}}</th>
                  <th class="customth" style="border-width: 0px;"></th>
                </tr>
                <tr>
                  <th class="customth" style="border-width: 0px;">OG</th>
                  <th class="customth" style="border-width: 0px;">{{SphVG}}</th>
                  <th class="customth" style="border-width: 0px;">{{CylVG}}</th>
                  <th class="customth" style="border-width: 0px;">{{AxeVG ? `${AxeVG}°` : ''}}</th>
                  <th class="customth" style="border-width: 0px;">{{AddVG}}</th>
                  <th class="customth" style="border-width: 0px;"></th>
                </tr>
              </thead>
            </table>
            <vs-tabs>
              <vs-tab label="Commande">
                <div class="vx-col w-full">
                  <small class="date-label">Date Livraison</small>
                  <datepicker :language="$vs.rtl ? langHe : langFr" name="dateLivraisonVerre" v-model="dateLivraisonVerre"></datepicker>
                </div>
                <div class="vx-col w-full mt-5">
                  <vs-input class="w-full" type="text" label="Numéro Bon de Livraison"  name="numBl" :value="numBl" @change.native="numBl=$event.target.value" />
                </div>
                <div class="vx-col w-full mt-5">
                  <vs-input class="w-full" type="text" label="G-optique"  name="gOptique" :value="gOptique" @change.native="gOptique=$event.target.value" />
                </div>
                <div class="vx-col w-full mt-5">
                  <span class="font-black">Fournisseur:</span> {{showFournisseurName(fournVerreId)}}
                </div>
                <div class="vx-col w-full mt-5">
                  <span class="font-black">Type de verre: </span> {{typeVerre}}
                </div>
                <div class="vx-col w-full mt-5">
                  <span class="font-black">Verre Droit: </span> {{libelleVerreD}}
                </div>
                <div class="vx-col w-full mt-5">
                  <span class="font-black">Verre Gauche: </span> {{libelleVerreG}}
                </div>
                <br><br><br><br><br>
              </vs-tab>
            </vs-tabs>

        </vs-prompt>
        <vs-prompt
          title="Montage"
          accept-text= "Valider"
          cancelText="Annuler"
          @accept="montageTerminer"
          :is-valid="validFormMontage"
          :active.sync="activePromptValideMontage">

            <div class="vx-col w-full">
              <vs-input class="w-full" name="Monteur" label="Monteur" :value="Monteur" @change.native="Monteur=$event.target.value" />
            </div>
            <div class="vx-col w-full mt-5">
              <small class="date-label">Heure début</small> <br>
              <flat-pickr  class="w-full" :config="configdateTimePicker" v-model="HeureDebutMontage" />
            </div>
            <div class="vx-col w-full mt-5">
              <small class="date-label">Heure fin</small> <br>
              <flat-pickr  class="w-full" :config="configdateTimePicker" v-model="HeureFinMontage" />
            </div>
        </vs-prompt>
        <vs-prompt
          title="Vérification Montage"
          accept-text= "Valider"
          cancelText="Annuler"
          @accept="verificationMontage"
          :is-valid="validFormMontageValidation"
          :active.sync="activePromptValideMontageValidation">

            <div class="vx-col w-full">
              <vs-input class="w-full" name="montageValiderPar" label="Montage validé par" :value="montageValiderPar" @change.native="montageValiderPar=$event.target.value" />
            </div>
            <div class="vx-col w-full mt-5">
              <small class="date-label">Heure de validation</small> <br>
              <flat-pickr  class="w-full" :config="configdateTimePicker" v-model="HeureValidationMontage" />
            </div>
        </vs-prompt>
        <vs-table multiple noDataText="Aucune donnée" v-model="selected" stripe pagination max-items="50" search :data="commandes">
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                <span class="mr-2 leading-none">Actions</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>Supprimer</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>CSV</span>
                  </span>
                </vs-dropdown-item>

              </vs-dropdown-menu>
            </vs-dropdown>
            <template v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">
              <vs-select placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4 ml-4" v-model="idOfficine" @change="getCommandesVerres(idOfficine)">
                <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
              </vs-select>
            </template>
          </template>
          <template slot="thead">
            <vs-th>
              N°
            </vs-th>
            <vs-th sort-key="dateCommande">
              Date cmde
            </vs-th>
            <vs-th sort-key="NumVente">
              N°vente
            </vs-th>
            <!-- <vs-th>
              Agence
            </vs-th> -->
            <vs-th>
              Oeil
            </vs-th>
            <vs-th sort-key="libelleVerreD">
              Verre / Sup
            </vs-th>
            <vs-th>
              Sph
            </vs-th>
            <vs-th>
              Cyl
            </vs-th>
            <vs-th>
              Axe
            </vs-th>
            <vs-th>
              Add
            </vs-th>
            <vs-th sort-key="nomClient">
              Client
            </vs-th>
            <vs-th>
              Verre
            </vs-th>
            <vs-th>
              Montage
            </vs-th>
            <vs-th>Actions</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  {{indextr + 1}}
                </vs-td>
                <vs-td>
                  {{tr.dateCommande | moment("calendar", "July 10 2011") }}
                </vs-td>
                <vs-td>
                  {{tr.NumVente}}
                </vs-td>
                <!-- <vs-td>
                  {{getEtiquette(tr.IdMont1)}}
                </vs-td> -->
                <!-- <vs-td>
                  {{ getOfficineById(tr.IdOfficine) ? getOfficineById(tr.IdOfficine).NomOffic : ''}}
                </vs-td> -->
                <vs-td>
                  D <br> G
                </vs-td>
                <vs-td>
                  <template>
                    {{tr.libelleVerreD}} <br> {{tr.libelleVerreG}}
                  </template>
                </vs-td>
                <vs-td>
                  {{tr.SphVD}} <br> {{tr.SphVG}}
                </vs-td>
                <vs-td>
                  {{tr.CylVD}} <br> {{tr.CylVG}}
                </vs-td>
                <vs-td>
                  {{tr.AxeVD}} <br> {{tr.AxeVG}}
                </vs-td>
                <vs-td>
                  {{tr.AddVD}} <br> {{tr.AddVG}}
                </vs-td>
                <vs-td>
                  {{tr.NomClient}} <br> {{tr.PrenomsClient}}
                </vs-td>
                <vs-td>
                  <template v-if="tr.livre">
                    <vs-chip transparent color="primary">
                      Livrée
                    </vs-chip>
                  </template>
                  <template v-else>
                    <vs-chip transparent color="warning">
                      <span @click="validCommandeVerreForm(tr)">Encours</span>
                    </vs-chip>
                  </template>
                </vs-td>
                <vs-td>
                  <template v-if="tr.montage">
                    <template v-if="tr.montageTerminer">
                      <template v-if="tr.Monteur !=='' && tr.montageValiderPar">
                        <vs-chip transparent color="primary">
                          Terminé
                        </vs-chip>
                      </template>
                      <template v-else>
                        <vs-chip transparent color="success">
                          <span @click="verificationMontagePrompt(tr)">Verification</span>
                        </vs-chip>
                      </template>
                    </template>
                    <template v-else>
                      <vs-chip transparent color="warning">
                        <span @click="validMontage(tr)">Encours</span>
                      </vs-chip>
                    </template>
                  </template>
                  <template v-else>
                    <vs-chip transparent color="danger">
                      Pas débuter
                    </vs-chip>
                  </template>
                </vs-td>
                <vs-td>
                  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                    <div class="dropdown-button-container">
                      <vs-dropdown>
                        <vs-button class="btn-drop" icon="more_vert"></vs-button>
                        <vs-dropdown-menu>
                          <vs-dropdown-item @click="showDetailsCommandeVerre(tr)">Détails</vs-dropdown-item>
                          <!-- <vs-dropdown-item>Modifier </vs-dropdown-item>
                          <vs-dropdown-item >Supprimer </vs-dropdown-item> -->
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>

                    <!-- <feather-icon title="Supprimer" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr._id, tr2.RefCmmd)" /> -->
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { en, he, fr } from 'vuejs-datepicker/src/locale'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'
export default {
  data () {
    return {
      etiquettes: [],
      idOfficine: null,
      activeUserInfos: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      selected: [],
      activePromptDetail: false,
      commandeVerre: null,
      activePromptCommandeVerre: false,
      numBl: null,
      gOptique: null,
      NumCmmde: '',
      RefCmmd: '',
      fournVerre1: null,
      fournVerre2: null,
      fournVerre3: null,
      RefVerG1: null,
      RefVerD1: null,
      RefVerG2: null,
      RefVerD2: null,
      RefVerG3: null,
      RefVerD3: null,

      dateCommandeVerre1: null,
      dateCommandeVerre2: null,
      dateCommandeVerre3: null,

      idClient: null,
      typeVerre: '',
      typeVerre2: '',
      typeVerre3: '',

      SphVD: '',
      CylVD: '',
      AxeVD: '',
      AddVD: '',
      SphVG: '',
      CylVG: '',
      AxeVG: '',
      AddVG: '',

      activePromptValideCommandeVerre: false,
      commandeVerreId: null,
      fournVerreId: null,
      commandeVerre1: null,
      libelleVerreD: '',
      libelleVerreG: '',

      fournVerreId2: null,
      commandeVerre2: null,
      libelleVerreD2: '',
      libelleVerreG2: '',

      fournVerreId3: null,
      commandeVerre3: null,
      libelleVerreD3: '',
      libelleVerreG3: '',

      dateLivraisonVerre: null,
      dateLivraisonVerre2: null,
      dateLivraisonVerre3: null,

      activePromptValideMontage: false,
      Monteur: '',
      HeureDebutMontage: null,
      HeureFinMontage: null,

      activePromptValideMontageValidation: false,
      montageValiderPar: '',
      HeureValidationMontage: null,

      activePromptRetourCommandeVerre: false,

      langHe: he,
      langEn: en,
      langFr: fr,
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        locale: FrenchLocale
      }
    }
  },
  components: {
    Datepicker,
    flatPickr
  },
  computed: {
    commandes () {
      return this.$store.state.commande_verre.verresCommandes
    },
    ventes () {
      return this.$store.state.atelier.ventes
    },
    sortVentesByDate () {
      return this.$store.getters['atelier/sortVentesByDate']
    },
    officines () {
      return this.$store.state.officine.officines
    },
    validForm () {
      return this.RefVerG1 !== ''
    },
    validFormMontage () {
      return this.Monteur !== ''
    },
    validFormMontageValidation () {
      return this.montageValiderPar !== ''
    },
    fournisseur_verres_data () {
      return this.$store.state.verre.fournisseurs_verres
    }
  },

  methods: {
    selectUserOfficine (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },
    showFournisseurName (id) {
      return id ? this.$store.getters['verre/showFournisseurName'](id) : ''
    },
    showDetailsCommandeVerre (data) {
      this.activePromptDetail = true
      this.commandeVerre = data
    },
    activeUserInfo () {

    },
    validCommandeVerreForm (data) {
      this.idClient = data.IdClient
      this.commandeVerreId = data._id

      this.RefCmmd = data.NumVente
      this.NumCmmde = data.NumCmmde
      this.SphVD = data.SphVD
      this.CylVD = data.CylVD
      this.AxeVD = data.AxeVD
      this.AddVD = data.AddVD
      this.SphVG = data.SphVG
      this.CylVG = data.CylVG
      this.AxeVG = data.AxeVG
      this.AddVG = data.AddVG

      this.typeVerre = data.typeVerre
      this.fournVerreId = data.fournVerreId
      this.libelleVerreD = data.libelleVerreD
      this.libelleVerreG = data.libelleVerreG

      this.activePromptValideCommandeVerre = true
    },
    valideLivraisonCommandeVerre () {
      const payload = {
        id: this.commandeVerreId,
        idClient: this.idClient,
        IdUser: this.IdUser,
        RefCmmd: this.RefCmmd,
        NumCmmde: this.NumCmmde,
        dateLivraison: new Date(new Date(this.dateLivraisonVerre)).toISOString().split('T')[0],
        numBl: this.numBl,
        gOptique: this.gOptique
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('commande_verre/valideLivraisonCommandeVerre', payload)
        .then(() => {
          // this.getVenteForAtelier()
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    retourCommandeVerreForm () {
      this.activePromptRetourCommandeVerre = true
    },
    RetourCommandeVerre () {
      const payload = {
        RefCmmd: this.RefCmmd,
        NumCmmde: this.NumCmmde,
        dateRetour: new Date(new Date(this.dateRetour)).toISOString().split('T')[0],
        motifRetour: this.motifRetour,
        id: this.commandeVerreId,
        idClient: this.idClient,
        IdUser: this.IdUser
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('commande_verre/RetourCommandeVerre', payload)
        .then(() => {
          // this.getVenteForAtelier()
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    validMontage (data) {
      this.idClient = data.IdClient
      this.commandeVerreId = data._id
      this.RefCmmd = data.NumVente
      this.NumCmmde = data.NumCmmde
      this.Monteur = `${this.activeUserInfos.nom ? this.activeUserInfos.nom : ''} ${this.activeUserInfos.prenoms ? this.activeUserInfos.prenoms : ''}`
      this.activePromptValideMontage = true
    },
    montageTerminer () {
      const payload = {
        id: this.commandeVerreId,
        RefCmmd: this.RefCmmd,
        idClient: this.idClient,
        IdUser: this.IdUser,
        HeureDebutMontage: new Date(new Date(this.HeureDebutMontage)),
        HeureFinMontage: new Date(new Date(this.HeureFinMontage)),
        Monteur: this.Monteur
      }

      this.$store.dispatch('commande_verre/valideMontage', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    verificationMontagePrompt (data) {
      this.idClient = data.IdClient
      this.commandeVerreId = data._id
      this.RefCmmd = data.NumVente
      this.montageValiderPar = `${this.activeUserInfos.nom ? this.activeUserInfos.nom : ''} ${this.activeUserInfos.prenoms ? this.activeUserInfos.prenoms : ''}`
      this.activePromptValideMontageValidation = true
    },
    verificationMontage () {
      const payload = {
        id: this.commandeVerreId,
        RefCmmd: this.RefCmmd,
        idClient: this.idClient,
        IdUser: this.IdUser,
        HeureValidationMontage: new Date(new Date(this.HeureValidationMontage)),
        montageValiderPar: this.montageValiderPar
      }

      this.$store.dispatch('commande_verre/verificationMontage', payload)
        .then(() => {
          this.getCommandesVerres(this.idOfficine)
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    commandeFournVerr (data) {
      this.idClient = data.IdClient
      this.RefCmmd = data.commande.RefCmmd
      this.RefVerG1 = data.commande.RefVerG1
      this.RefVerD1 = data.commande.RefVerD1
      this.RefVerG2 = data.commande.RefVerG2
      this.RefVerD2 = data.commande.RefVerD2
      this.RefVerG3 = data.commande.RefVerG3
      this.RefVerD3 = data.commande.RefVerD3
      this.activePromptCommandeVerre = true

      this.SphVD = data.commande.SphVD
      this.CylVD = data.commande.CylVD
      this.AxeVD = data.commande.AxeVD
      this.AddVD = data.commande.AddVD
      this.SphVG = data.commande.SphVG
      this.CylVG = data.commande.CylVG
      this.AxeVG = data.commande.AxeVG
      this.AddVG = data.commande.AddVG
    },
    PassCommandeVerre () {
      const payload = {
        RefCmmd: this.RefCmmd,
        SphVD: this.SphVD,
        SphVG: this.SphVG,
        CylVD: this.CylVD,
        CylVG: this.CylVG,
        AxeVD: this.AxeVD,
        AxeVG: this.AxeVG,
        AddVD: this.AddVD,
        AddVG: this.AddVG,
        fournVerre: this.fournVerre1,
        typeVerre: this.typeVerre,
        RefVerD: this.RefVerD1,
        RefVerG: this.RefVerG1,
        dateCommandeVerre: new Date(new Date(this.dateCommandeVerre1)).toISOString().split('T')[0],
        // fournVerre2: this.fournVerre2,
        // typeVerre2: this.typeVerre2,
        // RefVerD2: this.RefVerD2,
        // RefVerG2: this.RefVerG2,
        // dateCommandeVerre2: new Date(new Date(this.dateCommandeVerre2)).toISOString().split('T')[0],
        // fournVerre3: this.fournVerre3,
        // typeVerre3: this.typeVerre3,
        // RefVerD3: this.RefVerD3,
        // RefVerG3: this.RefVerG3,
        // dateCommandeVerre3: new Date(new Date(this.dateCommandeVerre3)).toISOString().split('T')[0],
        idClient: this.idClient,
        IdUser: this.IdUser
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('client/valideCommandeFournVerre', payload)
        .then(() => {
          this.getVenteForAtelier()
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getArticleById (id) {
      this.$store.dispatch('stock/getArticleById', id)
        .then((response) => {
          /* console.log('Etiquette:', response.data.Etiquette) */
          this.etiquettes.push({
            idMonture: id,
            etiquette: response.data ? response.data.Etiquette : ''
          })
        })
        .catch((err) => {
          console.log('error article:', err)
        })
    },
    getEtiquette (id) {
      const monture = this.etiquettes.find((a) => a.idMonture === id)
      return monture ? monture.etiquette : ''
    },
    getCommandesVerres (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('commande_verre/getCommandesVerres', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          console.log('error commande verre:', err)
          this.$vs.loading.close()
        })
    },
    getVenteForAtelier () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('atelier/getVenteForAtelier')
        .then(() => {
          const ventes = this.$store.state.atelier.ventes
          if (ventes) {
            ventes.forEach((vente) => {
              this.getArticleById(vente.commande.IdMont1)
            })
          }
          this.$vs.loading.close()
        })
        .catch((err) => {
          this.$vs.loading.close()
          console.log('error ventes:', err)
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getOfficineById (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },
    getFournisseursVerres () {
      this.$store.dispatch('verre/getFournisseursVerres')
        .catch((error) => { console.log(error) })
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.verre.fournisseurs_verres.length > 0)) this.getFournisseursVerres()
    this.getVenteForAtelier()
  }
}
</script>

